.fixed-top{
    background: white;
    padding-top: 0;
    padding-bottom: 0;
    padding-top: .3rem;
}
.nav-logo{
    width:80px;
    height: 50px;

}

.nav-link {
    color: black !important;
    font-weight:bold;
    transition: color 0.4s ease-in;
    text-transform: uppercase;
}
.nav-link:hover{
    color:#ec4c4c !important;
}

.donate-button{
    color: white;
    text-transform: uppercase;
    position: fixed;
    top: 35px;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #ec4c4c;
    padding: .3rem 1rem;
    font-weight: bold;
    transition: all .4s ease;
}
.donate-button:hover{
    border: 1px solid black;
    background: transparent;
    color:#ec4c4c;
}

.progress-div{
    position: fixed;
    border-bottom: 8px solid #ec4c4c;
    left:0;
    height: 4px;
}
@media (max-width:500px) {

    .donate-button{
        top:30px;
        left: 50%;
    }
}