.landing-fluid {
    margin-top: 70px;
    padding: 0;
    background-color:black !important;
}
.landing-image{
    background-image: url("../images/landing_pic.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    max-width: 1900px;
    margin: auto;
}
.landing-image{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.landing-jumbotron{
    background: rgba(0, 0, 0, 0.8);
    padding: .8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.color-white{
    border: 1px solid white;
    width: 80%;
    margin: 0;
}
.landing-jumbotron h1{
    word-spacing:-0.2rem;
}
.landing-jumbotron p{
    margin-top: .8rem;
    padding: 0 1rem;
    opacity: 0.7;
}
.landing-learn-more{
    border: 1px solid #ec4c4c;
    padding: .2rem .6rem;
    transition: all .4s ease-in;

}
.landing-learn-more a{
    color: white;
    transition: all .4s ease-in;
}
.landing-learn-more:hover{
    border: 1px solid white;
    cursor: pointer;
}
.landing-learn-more:hover a{
    color: #ec4c4c;
}

@media (max-width: 600px) {
    .landing-jumbotron h1{
        font-size: 1.5rem;
        font-weight: bold;
    }
}