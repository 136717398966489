.footer-fluid{
    background: black;
    color: white;
    padding-bottom: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scroll-top{
    cursor: pointer;
    margin-top: -2.3rem;
    color:white;
    background-color: black;
    border-radius: 100%;
    padding: 0;
    display: block;
    animation: change 3s infinite ease-in;
}

@keyframes change {
    0%{
        transform: scale(.8);
    }
    50%{
        transform: scale(1);
    }
    100%{
        transform: scale(.8);
    }
}

.footer-container{
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footer-container h2{
    font-weight: bold;
}
.social-media-icon {
    margin-top: 1rem;
}
.social-media-icon i{
    margin: .3rem;
    font-size: 2.5rem;
    transition: all 0.3s ease-in;
}

.social-media-icon a:first-child{
    color: #3b5998;
}
.social-media-icon a:nth-child(2){
    color: #FD1D1D;
}
.social-media-icon a:last-child{
    color: #D44638;
}

.social-media-icon i:hover{
    opacity: 0.6;
}

.footer-main-content{
    margin-top: 2rem;
    display: flex;
    margin-bottom: 2rem;
    /* justify-content: center;
    align-items: center; */
}
.footer-main-content p{
    opacity: 0.7;
}
.footer-main-content h4{
    margin-bottom: .8rem;
}
.footer-about{
    margin-right: 1rem;
    max-width: 250px;
    text-align: start;
}
.footer-about p{
    margin: 0;
}

.footer-contact-us{
    /* max-width: 300px; */
    margin-right: 1rem;
}
.footer-contact-us p{
   margin: 0;
}
.footer-donate-btn a{
    color:white;
    text-transform: uppercase;
    font-weight: bold;
    padding: .2rem .4rem;
    border: 1px solid #ec4c4c;
    background: transparent;
    transition: all 0.3s ease-in; 
}
.footer-donate-btn a:hover{
    border: 1px solid white;
    color:#ec4c4c;
    background: transparent;
}

.copy-right{
    color: black;
    position: absolute;
    bottom: 0;
    padding: .3rem .6rem 0 .6rem;
    background-color:white;
}
.copy-right p{
    margin-bottom: 0;
}

@media (max-width:610px){
    .footer-main-content{
        flex-direction: column;
    }

    .footer-about,.footer-contact-us,.footer-support-us{
        margin-bottom: .8rem;
    }
    .footer-container h2{
        font-size: 1.4rem;
        text-align: center;
    }
}