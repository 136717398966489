.about-fluid{
    background-color: #fafafa;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.about-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image{
    flex:1;
    margin-right: 3rem;
}
.about-desc{
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about-desc h1{
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 2rem;
    position: relative;
}

.about-desc h1::after{
    content: '';
    position: absolute;
    bottom: -.01rem;
    background-color: #ec4c4c;
    left: 50%;
    transform: translateX(-50%);
}

.about-desc h1::before{
    content: '';
    position: absolute;
    bottom: -.4rem;
    background-color: #ec4c4c;
    left: 50%;
    transform: translateX(-50%);
}

.about-desc h1::before{
    width: 110%;
    height: 3px;
}

.about-desc h1::after{
    width: 110%;
    height: 3px;
}
.about-desc p {
    text-align: justify;
}
.about-learn-more{
    border: 1px solid #ec4c4c;
    padding: .2rem .6rem;
    transition: all .4s ease-in;
}

.about-learn-more a{
    color:black;
    transition: all .4s ease-in;
}

.about-learn-more a:hover{
    color: #ec4c4c;
}

.about-learn-more:hover{
    border: 1px solid black;
}


@media (max-width:500px){
    .about-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .about-image{
        margin: auto;
        margin-bottom: 1rem;
    }
    .about-desc h1{
        font-size: 3rem;
    }
}