*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Abel', sans-serif;
  overflow-x: hidden;
}
a{
  text-decoration: none !important;
}

/* .container{
  max-width: 960px;
} */

section{
  margin-top: 3rem;
  padding: .8rem 0;
}

img{
  width: 100%;
}