.donate-fluid{
    padding: 0;
    background-color:black !important;
    margin-top: 0;
    color: white;
}

.donate-bg-image{
    width: 100%;
    background-image: url("../images/donate_hand2.jpg");
    height: 35vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.black-opacity{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    position: relative;
}

.black-opacity h2{
    font-weight: bold;
    text-align: center;
}

.black-opacity .donate-now-button {
    border:1px solid #ec4c4c;
    padding: .5rem 2rem;
    color: white;
    margin-top: 2.5rem;
    transition: all 0.3s ease;
    font-weight: bold;
}

.black-opacity .donate-now-button:hover {
    color: #ec4c4c;
    border: 1px  solid white;
}
.fa-hand-holding-usd{
    color: #ec4c4c;
    margin-bottom: 2rem;
}

@media (max-width:600px){
    .donate-fluid{
        margin-top: 0;
    }
    .black-opacity h2{
        font-size: 1.3rem;
    }
    .black-opacity p{
        padding: 0 1rem;
        text-align: center;
    }
    .black-opacity .donate-now-button{
        margin-top: 1rem;
    }
    .fa-hand-holding-usd{
        margin-bottom: 1rem;
    }
}