.work-fluid {
  background: black;
  color: white;
  padding-bottom: 5rem;
}
.container-work {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container-work h1 {
  font-size: 4rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 2rem;
  position: relative;
  border-bottom: 1px solid #ec4c4c;
  padding: 0 2rem;
}

.container-work .color-red1 {
  border-top: 1px solid #ec4c4c;
  width: 12rem;
  margin-top: -1.3rem;
}

.container-work .color-red2 {
  border-top: 1px solid #ec4c4c;
  width: 8rem;
  margin-top: -0.3rem;
}

.card-container {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100%;
  text-align: left;
}

.first-second-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card1,
.card2,
.card3 {
  width: 300px;
  background-color: #fafafa;
  height: 430px;
  margin: 0.8rem;
  padding: 0.5rem;
  transition: all 0.2s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 15%;
  border-bottom-left-radius: 15%;
}
.card1 img,
.card2 img,
.card3 img {
  border-top-right-radius: 15%;
  border-bottom-left-radius: 15%;
}

.card1:hover,
.card2:hover,
.card3:hover {
  transform: scale(1.05);
}
/* .card1 img:hover,.card2 img:hover,.card3 img:hover{
    border-radius: 5%;
} */
.card1 p,
.card2 p,
.card3 p {
  font-size: 0.9rem;
  opacity: 0.7;
}

.card1 h6,
.card2 h6,
.card3 h6 {
  width: 100%;
  text-align: left !important;
  margin-top: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.card1 a,
.card2 a,
.card3 a {
  color: black;
  padding: 0.3rem 2rem;
  border: 1px solid #ec4c4c;
  margin-bottom: 0.6rem;
  transition: all 0.3s ease-in;
  font-weight: bold;
  border-top-right-radius: 20%;
  border-bottom-left-radius: 20%;
}
.card1 a:hover,
.card2 a:hover,
.card3 a:hover {
  color: #ec4c4c;
  border: 1px solid black;
}
.work-view-more {
  border: 1px solid #ec4c4c;
  padding: 0.5rem 2rem;
  color: white;
  margin-top: 3.5rem;
  transition: all 0.3s ease;
}

.work-view-more:hover {
  color: #ec4c4c;
  border: 1px solid white;
}
.card1 img,
.card2 img,
.card3 img {
  max-height: 200px;
  object-fit: cover;
  min-height: 200px;
}

.video-container {
  margin-top: 3rem;
}

.video-wrapper {
  max-width: 950px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}

.iframe-video {
  width: 100%;
  min-height: 270px;
  border: 2px solid white;
}

@media (max-width: 900px) {
  .card-container {
    flex-direction: column;
  }
  .third-card-container {
    margin-left: 2rem;
    align-self: flex-start;
  }
}

@media (max-width: 850px) {
  .iframe-video {
    min-height: 240px;
  }
}

@media (max-width: 600px) {
  .card-container {
    flex-direction: column;
  }
  .first-second-card-container {
    flex-direction: column;
  }
  .third-card-container {
    margin-left: 0;
    align-self: center;
  }
  .container-work h1 {
    font-size: 3rem;
  }
  .video-container {
    padding: 0;
  }
  .video-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .iframe-video {
    min-width: 220px;
  }
}
