.impact-fluid{
    padding: 0;
    margin-top: 0;
}
.impact-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.impact-container h1{ 
    font-size: 4rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
    border-bottom: 1px solid #ec4c4c;
    padding: 0 2rem .5rem 2rem;
}

.impact-container .color-red1{
    border-top: 1px solid #ec4c4c;
    width: 12rem;
    margin-top: -1.3rem;
}

.impact-container .color-red2 {
    border-top: 1px solid #ec4c4c;
    width: 8rem;
    margin-top: -.3rem;
}
.column-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
}

.impact-col-right, .impact-col-left{
    display: flex;
    justify-content: center;
    align-items: center;
}

.impact-col-left, .impact-col-right{
    text-align: center;
    text-transform: uppercase;
}

.col-left-impact1,.col-left-impact2{
    margin: 1rem;
    font-weight: bold;
}

.impact-col-left h2, .impact-col-right h2{
    font-size: 3rem;
    color: #ec4c4c;
}
.impact-view-more{
    border:1px solid #ec4c4c;
    padding: .5rem 2rem;
    color: black;
    margin-top: 1.5rem;
    transition: all 0.3s ease;
    font-weight: bold;
}

.impact-view-more:hover{
    color: #ec4c4c;
    border: 1px  solid black;
}

@media (max-width:620px){
    .column-wrapper{
        flex-direction: column;
    }
    .impact-container h1{
        font-size: 3rem;
    }
}