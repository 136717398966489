.map-fluid{
    margin-top: 3rem;
    margin-bottom: 5rem;
}
.google-map-bansbari{
    width: 100%;
    height: 400px;
    border: 0;
    background: black;
}
.google-map-bansbari:hover{
    opacity: 0.8;
}

